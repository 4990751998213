<template>
  <div class="overview-lookalikes">
    <div class="text-h6 font-weight-bold mb-4">
      Influencer Interests
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            :color="isThemeLight ? 'black' : null"
            v-bind="attrs"
            v-on="on"
          >
            help_outline
          </v-icon>
        </template>
        <span>
          We determine interests by analyzing posts for caption texts, @mentions, #hashtags and location tags.
        </span>
      </v-tooltip>
    </div>

    <v-card class="border-radius-1em box-shadow-soft d-flex flex-column justify-between">
      <v-card-text class="flex-grow-1">
        <v-chip-group column>
          <v-chip
            v-for="item in interests.slice(0, 6)"
            :key="item.id"
            color="gray"
            outlined
          >
            <v-icon
              v-if="interestIconsMap[item.name]"
              color="secondary"
              small
              left
            >
              {{ interestIconsMap[item.name] }}
            </v-icon>

            {{ item.name }}
          </v-chip>
        </v-chip-group>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          color="primary"
          text
          small
          @click="shouldShowDialog = true"
        >
          View More
        </v-btn>
      </v-card-actions>
    </v-card>

    <!-- the dialog table -->
    <v-dialog
      v-model="shouldShowDialog"
      width="400"
      scrollable
    >
      <v-card>
        <v-card-title>
          Influencer Interests
        </v-card-title>

        <v-card-text class="make-it-scrollable">
          <v-list dense>
            <template
              v-for="(item, index) in interests"
            >
              <v-list-item
                :key="item.id"
              >
                <v-list-item-icon
                  class="mr-3"
                >
                  <v-icon
                    v-if="interestIconsMap[item.name]"
                    color="secondary"
                  >
                    {{ interestIconsMap[item.name] }}
                  </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.name }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-divider
                v-if="index < (interests.length - 1)"
              ></v-divider>
            </template>
          </v-list>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            text
            color="primary"
            @click="shouldShowDialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// Import icons map for interests
import interestIconsMap from '@/helpers/interestIconsMap'

// Export the SFC
export default {
  // Name of the component
  name: "InfluencerInterests",

  // Accept incoming data from parent
  props: {
    userData: {
      type: Object,
      required: true
    }
  },

  // Define local data variables
  data: () => ({
    interestIconsMap,
    shouldShowDialog: false
  }),

  // Define computable data variables
  computed: {
    /**
     * Get all the interest items to be shown
     *
     * @returns {Array}
     */
    interests() {
      return this.userData.data.user_profile?.interests || []
    }
  }
}
</script>
